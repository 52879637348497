@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

@import '../node_modules/tailwindcss/base.css' layer(tailwindcss);
@import '../node_modules/tailwindcss/components.css' layer(tailwindcss);
@import '../node_modules/tailwindcss/utilities.css' layer(tailwindcss);

// Import PrimeNG styles after Tailwind base to reset browser styles.
@import 'primeng/resources/themes/mdc-light-indigo/theme.css';
@import 'primeng/resources/primeng.css';
@import './mat-theme.scss';
@import './global-styles/table.scss';

:root {
	--bg-green: #46c99e;
	--bg-green-light: #e7f8f2;
	--bg-purple: #c87eec;
	--bg-purple-light: #cd93ea26;
	--bg-sky: #4d7bca;
	--bg-sky-light: #f1f7ff;
	--bg-gray: #dedee06e;
	--bg-gray-light: #f5f6f8;
	--bg-orange: #f7b924;
	--bg-orange-light: #f5e1b17c;
	--bg-red: #e53e3e;
	--bg-red-light: #ffe0e97c;
	--bg-pink: #f16194;
	--bg-pink-light: #f783ac3f;
	--bg-black: #000000;
	--bg-black-light: #eaecf1;
	--bg-black-light-hover: #d3d3d3;
	--gray: #8d8989;
}
* {
	font-family: 'Rubik', sans-serif;
}

body {
	font-family: 'Rubik', sans-serif !important;
	background-color: #f1f7ff;
}

h1 {
	font-family: 'Rubik', sans-serif !important;
}

main {
	position: relative;
}

html,
body {
	height: 100%;
}

/* Styles for Webkit browsers */
::-webkit-scrollbar {
	width: 7px; /* width of the entire scrollbar */
	height: 5px;
}

::-webkit-scrollbar-track {
	background: #f1f1f1; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
	background: #888; /* color of the scroll thumb */
	border-radius: 20px; /* roundness of the scroll thumb */
}

::-webkit-scrollbar-thumb:hover {
	background: #555; /* color of the scroll thumb when hovering */
}

/* -------------------------------------------------------------------------- */
/*                           Custom p-button styles                           */
/* -------------------------------------------------------------------------- */
.p-button {
	border: none;
	padding: 7px;
}
.gradient > button,
.gradient > .p-splitbutton .p-splitbutton-defaultbutton {
	background: none !important;
	background-image: linear-gradient(to right, #4776e6 0%, #8e54e9 51%, #4776e6 100%) !important;
	text-align: center !important;
	text-transform: uppercase !important;
	transition: 0.5s !important;
	background-size: 200% auto !important;
	color: white !important;
	box-shadow: 0 0 20px #eee !important;
	border-radius: 5px !important;
	padding: 7px !important;
}

.gradient > .p-splitbutton .p-splitbutton-defaultbutton {
	border-radius: 5px 0 0 5px !important;
}
.p-splitbutton-menubutton {
	border-left: 1px solid #fff !important;
	background: #8e54e9 !important;
}
.p-dropdown-filter.p-inputtext.p-component {
	margin-right: 0;
}

.gradient > button:hover,
.gradient > .p-splitbutton .p-splitbutton-defaultbutton:hover {
	background-position: right center !important; /* Change the direction of the change here */
	color: #fff !important;
	text-decoration: none !important;
}

.gradient > button:disabled,
.gradient > button[disabled] {
	background: #d3d2d2 !important;
	color: #666666 !important; /* Dimmed text color for disabled state */
	cursor: not-allowed !important;
	box-shadow: none !important;
	border-radius: 5px !important;
}

.p-button .p-button-icon-left {
	margin-right: 0;
	padding-left: 10px;
}

/* -------------------------------------------------------------------------- */
/*                                  DropDown                                  */
/* -------------------------------------------------------------------------- */
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
	text-wrap: wrap;
	word-break: break-all;
}

/* -------------------------------------------------------------------------- */

::ng-deep.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
	padding-top: 12px !important;
	padding-bottom: 12px !important;
}
.mat-mdc-form-field-infix {
	min-height: 30px;
}
.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
	top: 25px;
}
.mat-select-panel {
	z-index: 10000 !important; /* Adjust as needed */
}

.p-float-label label {
	right: 10px;
	width: fit-content;
}
span > .p-inputtext:enabled:focus,
.p-inputtext:enabled:focus {
	border: none;
	border-bottom: 1.8px solid var(--bg-purple);
	box-shadow: none;
}
.p-calendar,
.p-calendar.p-calendar-w-btn.p-focus {
	border: none;
	box-shadow: none;
}
//set required field color to red also in focus
span > .p-inputtext.ng-invalid:enabled:focus {
	border-color: #e53e3e !important;
	box-shadow: none;
}

.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__shadow {
	background-color: rgb(50, 170, 118);
}

.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__track::after {
	background-color: rgb(113, 226, 147) !important;
}

.p-button.p-button-outlined {
	color: #4d7bca;
}

.p-toast .p-toast-message.p-toast-message-success {
	background: #fff !important;
	border-right: 3px solid rgb(50, 170, 118) !important;
	border-radius: 10px;
}
.p-toast .p-toast-message.p-toast-message-warn {
	background: #fff !important;
	border-right: 3px solid rgb(255, 193, 7) !important;
	border-radius: 10px;
}

.p-toast .p-toast-message.p-toast-message-error {
	background: #fff !important;
	border-right: 3px solid rgb(229, 62, 62) !important;
	border-radius: 10px;
}

.p-toast .p-toast-message.p-toast-message-info {
	background: #fff !important;
	border-right: 3px solid rgb(50, 114, 170) !important;
	border-radius: 10px;
}
.p-toast .p-toast-message .p-toast-message-content {
	padding: 0.5rem;
}

input,
textarea {
	border: none;
	border-radius: 0px;
	border-bottom: 1.8px solid var(--gray);
}
input:focus {
	border: none;
	border-bottom: 1.8px solid var(--bg-green);
}

.p-multiselect-filter.p-inputtext.p-component {
	margin-top: 1rem;
	margin: 0 5px;
	padding: 5px;
}
.p-multiselect-filter-icon {
	display: none;
}
.p-multiselect-filter-container {
	margin-top: 1rem;
}
.p-multiselect-panel.p-multiselect-items.p-multiselect-item.p-checkbox {
	margin-right: 0;
	margin-left: 0.5rem;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
	rotate: 180deg;
}

.p-toast-message-content {
	justify-content: space-between;
}

.p-tabview .p-tabview-nav li {
	width: 50%;
}

/* -------------------------------------------------------------------------- */
/*                                  Paginator                                 */
/* -------------------------------------------------------------------------- */
.p-paginator-icon {
	transform: rotate(180deg);
}

/* -------------------------------------------------------------------------- */
/*                                    Title                                   */
/* -------------------------------------------------------------------------- */
.title,
.md-title {
	font-size: 1.8rem;
	font-weight: bold;
	background: linear-gradient(45deg, var(--bg-sky), var(--bg-purple));
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

@media (max-width: 768px) {
	.title {
		font-size: 1.5rem;
	}
}

.md-title {
	font-size: 1.2rem !important;
}

/* -------------------------------------------------------------------------- */
/*                           Multi section toolbar                            */
/* -------------------------------------------------------------------------- */

.multiSelectedActions {
	position: fixed;
	top: 0;
	right: 0;
	width: 95%;
	height: auto;
	animation: slideFromTop 0.5s ease-in-out;
	z-index: 10000;
	background: var(--bg-green);
	color: white;
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	margin: 10px;
}

@keyframes slideFromTop {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(0);
	}
}

/* -------------------------------------------------------------------------- */
/*                                   Stepper                                  */
/* -------------------------------------------------------------------------- */
.p-stepper .p-stepper-panels {
	background: transparent;
}

/* -------------------------------------------------------------------------- */
/*                                   Dialog                                   */
/* -------------------------------------------------------------------------- */

.p-dialog {
	max-height: 100%;
}

/* -------------------------------------------------------------------------- */
/*                                 DatePicker                                 */
/* -------------------------------------------------------------------------- */
.p-datepicker .p-timepicker {
	direction: ltr;
}

/* -------------------------------------------------------------------------- */
/*                                    Menu                                    */
/* -------------------------------------------------------------------------- */
.p-menuitem-text {
	padding-inline: 5px;
}
.delete-item * {
	background-color: var(--bg-red-light) !important;
	color: var(--bg-red) !important;
}

/* -------------------------------------------------------------------------- */
/*                                Split button                                */
/* -------------------------------------------------------------------------- */
.p-tieredmenu .p-tieredmenu-root-list {
	direction: rtl;
}

/* -------------------------------------------------------------------------- */
/*                                   Sidebar                                  */
/* -------------------------------------------------------------------------- */
// .p-sidebar-left {
// 	width: auto;
// }

.horizontal-divider {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	position: relative;
	margin: 16px 0;
}

.horizontal-divider::before,
.horizontal-divider::after {
	content: '';
	flex: 1;
	border-top: 1px solid #ccc;
	margin: 0 8px;
}

.horizontal-divider span {
	white-space: nowrap;
	font-size: 16px;
	color: var(--gray);
}
