// custom-theme.scss

@use '@angular/material' as mat;


$primary: mat.m2-define-palette(mat.$m2-blue-palette, 500);


$accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400); 


$theme: mat.m2-define-light-theme((
  color: (
    primary: $primary,
    accent: $accent
  )
));

.cdk-overlay-pane{
  position: absolute;
  top: 0;
  z-index: 99999 !important;
}

@include mat.all-component-themes($theme);