.row-header > th {
	background-color: var(--bg-green-light);
	font-weight: 700;
	text-align: start;
	border-top: 2px solid var(--bg-green);
}
:host::ng-deep.p-datatable.p-datatable-header {
	padding: 0;
}
:host::ng-deep.p-datatable .p-datatable-thead > tr > th {
	padding: 7px;
	font-size: 14px;
}

:host::ng-deep .p-datatable .p-datatable-table {
	border-collapse: separate;
	border-spacing: 0 6px;
	background-color: transparent;
}

:host::ng-deep .p-datatable-tbody > tr {
	position: relative;
	width: 100%;
	table-layout: fixed;
	margin-bottom: 1px;
}

:host::ng-deep .p-datatable-tbody > tr::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	border-right: 3px solid var(--bg-green);
	background-color: #fff;
	margin: 1px 0;
}

:host::ng-deep .p-datatable-tbody > tr > td {
	background-color: transparent;
	padding: 2px 10px;
	text-align: start;
	font-size: 14px;
}

:host::ng-deep.p-paginator {
	padding: 0;
}
